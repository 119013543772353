import { Container, Row, Col } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import projImg1 from "../assets/img/chatbot.jpg";
import projImg2 from "../assets/img/Mentorship.png";
import projImg3 from "../assets/img/egkn.png";

export const Projects = () => {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1 
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1 
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1 
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1 
        }
    };
    
    const projects = [
        {
            title: "Advanced Search Engine for Movies",
            description: "The Movie Chatbot is a web-based chat application designed to simplify movie-related queries through natural language interactions. As a result of my dissertation project at university, this chatbot offers an engaging and user-friendly experience for movie enthusiasts. Users can simply ask questions about movies, and the chatbot responds in a conversational and informative manner.",
            imgUrl: projImg1,
            tech: "Backend: Python with Flask; NLP & ML: PyTorch, SpaCy; API Integration: TMDB API; Frontend: JavaScript",
        },
        {
            title: "Mentor-Mentee Matching System for Deutsche Bank",
            description: "As part of a university group project, I served as the Backend Developer for the Mentor-Mentee Matching System created for Deutsche Bank. Our mission was to design and develop a comprehensive platform that simplifies the organization and management of mentorship programs within the company. This system streamlines various aspects, including scheduling meetings and providing feedback, to enhance the mentorship experience.",
            imgUrl: projImg2,
            tech: "Backend: TypeScript; Frontend: React & Bootstrap; Database: PostgreSQL",
        },
        {
            title: "Unified State Real Estate Cadastre",
            description: "As a full-stack software developer, I am currently engaged in the development and enhancement of the \"Unified State Real Estate Cadastre\" – an advanced information system designed to consolidate and manage comprehensive data regarding land and legal cadastres. This pivotal project plays a crucial role in aligning real estate information management with the standards and regulations set forth by the Land Code and relevant laws.",
            imgUrl: projImg3,
            tech: "Backend: Java; Frontend: React; Database: PostgreSQL",
        },
    ];

  return (
    <section className="project" id="projects">
        <Container>
            <Row>
                <Col size={12}>
                    <h2>Projects</h2>
                    <p>Here are some of my projects:</p>
                    <Row>
                    <Carousel responsive={responsive} infinite={true}>
                        {projects.map((project, index) => {
                            return (
                                <ProjectCard
                                    key={index}
                                    {...project}
                                />
                            )
                        })}
                    </Carousel>
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
  )
}