import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";
import javaIcon from "../assets/img/java-icon.svg";
import pythonIcon from "../assets/img/python-icon.svg";
import jsIcon from "../assets/img/javascript-icon.svg";
import reactIcon from "../assets/img/react-icon.svg";
import sqlIcon from "../assets/img/sql-icon.svg";

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };


    return (
        <section className="skill" id="skills">
            <Container>
                <Row>
                    <Col>
                        <div className="skill-box">
                            <h2>
                                Skills
                            </h2>
                            <p>Description</p>
                            <Carousel responsive={responsive} infinite={true} className="skill-slider">
                                <div className="item">
                                    <img src={javaIcon} alt="skill"></img>
                                    <h5>Java</h5>
                                </div>

                                <div className="item">
                                    <img src={pythonIcon} alt="skill"></img>
                                    <h5>Python</h5>
                                </div>

                                <div className="item">
                                    <img src={jsIcon} alt="skill"></img>
                                    <h5>JavaScript</h5>
                                </div>

                                <div className="item">
                                    <img src={reactIcon} alt="skill"></img>
                                    <h5>ReactJS</h5>
                                </div>

                                <div className="item">
                                    <img src={sqlIcon} alt="skill"></img>
                                    <h5>PostgreSQL</h5>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-left" src={colorSharp} alt="color"/>
            <img className="background-image-right" src={colorSharp} alt="color"/>
        </section>
    )
}