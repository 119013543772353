import { Col, Container, Row } from "react-bootstrap"

export const About = () => {
    return (
        <section className="about" id="about">
            <Container>
                <Row>
                    <Col>
                        <h2>About Me</h2>
                        <p>Hello! I'm Beibit, a dedicated and passionate Software Developer with a degree in Computer Science from the University of Warwick. My journey into the world of technology has been driven by my curiosity and a relentless desire to solve complex problems with elegant and efficient solutions.

During my time at Warwick, I immersed myself in the fundamentals of computer science, developing a solid foundation in programming, algorithms, and system design. This rigorous academic training has equipped me with the skills necessary to tackle diverse challenges in software development.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}