import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import emailjs from 'emailjs-com';

export const Contact = () => {
    const formInitialDetails = {
        email: '',
        message: ''
    }
    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Send');
    const [status, setStatus] = useState({});

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonText("Sending...");

        // Check if the email field is empty or not a valid email format
        if (!formDetails.email || !isValidEmail(formDetails.email)) {
            setStatus({ success: false, message: 'Please enter a valid email address.' });
            setButtonText("Send");
            return;
        }

        // Check if the message field is empty
        if (!formDetails.message) {
            setStatus({ success: false, message: 'Please enter a message.' });
            setButtonText("Send");
            return;
        }

        emailjs.sendForm('service_8b745fx', 'template_i57t45n', e.target, 'TgwYZVLEX8MZMSPmE')
            .then((result) => {
                setStatus({ succes: true, message: 'Message sent successfully'});
            }, (error) => {
                setStatus({ succes: false, message: 'Something went wrong, please try again later.'});
                console.log(error.text);
            });

        setButtonText("Send");
        setFormDetails(formInitialDetails);
    };

    const isValidEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    return (
        <section className="contact" id="connect">
            <Container>
                <Row className="align-items-center">
                    <Col size={12} md={6}>
                        <img src={contactImg} alt="Contact Me"/>
                    </Col>

                    <Col size={12} md={6}>
                        <h2>Get In Touch</h2>
                        <form onSubmit={handleSubmit}>
                            <Row className="align-items-center justify-content-center">
                                <Col size={12} sm={6} className="px-1" style={{width: "100%"}}>
                                    <input type="email" 
                                    name="from_email"
                                    value={formDetails.email} placeholder="Email Address" 
                                    onChange={(e) => onFormUpdate('email', e.target.value)} />
                                </Col>

                                <Col size={12} className="px-1" style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                    <textarea rows="6" 
                                    value={formDetails.message} 
                                    name="html_message"
                                    placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                                    <button type="submit"><span>{buttonText}</span></button>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-center">
                                <Col style={{ display: "flex", justifyContent: "center" }}>
                                    {status.message && (
                                        <p className={status.success === false ? "danger" : "success"}>
                                            {status.message}
                                        </p>
                                    )}
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}