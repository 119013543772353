import { Container, Row, Col } from "react-bootstrap";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from '../assets/img/github-icon.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <span>BEIBIT TEMIRZHANOV</span>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon" style={{display: "flex", justifyContent: "flex-end"}} >
              <a href='https://www.linkedin.com/in/beibit-temirzhanov-367664231/'><img src={navIcon1} alt="LinkedIn" /></a>
              <a href='https://github.com/tmrzhnv' ><img src={navIcon2} alt="" style={{ width: "60%" }}/></a>
            </div>
            <p>&copy; 2023 Beibit Temirzhanov. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}