import { Col, Container, Row } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";
import headerImg from "../assets/img/my-photo.jpg";



export const Banner = () => {

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <span className="tagline">Welcome to my Portfolio</span>
                        <h1>{`Hi, I'm Beibit `}<span className="wrap"><br />
                        <TypeAnimation
                            sequence={[
                                'Software Developer',
                                1000,
                                'Web Developer',
                                1000,
                            ]}
                            speed={50}
                            repeat={Infinity}
                            />
                        </span></h1>
                    </Col>

                    <Col xs={12} md={6} xl={5} className="banner_image">
                        <img src={headerImg} alt="Header Img" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}