import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, tech }) => {
  return (
    <Col>
      <div className="proj-card">
        <div className="proj-img">
          <img src={imgUrl} alt={title} />
        </div>
        <div className="proj-details">
          <h4>{title}</h4>
          <p>{description}</p>
          <p>{tech}</p>
        </div>
      </div>
    </Col>
  );
};

