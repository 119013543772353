import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon4 from '../assets/img/github-icon.svg';

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }

    const downloadFile = () => {
        const link = document.createElement("a");
        link.href = '/cv.pdf';
        link.target = '_blank'; // Open in a new tab
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    
    return (
    <Navbar expand="lg" className={scrolled ?  "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="#home">TMRZHNV</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">

            <Nav.Link href="#home" 
            className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'}
            onClick={() => onUpdateActiveLink('home')}>
                Home
            </Nav.Link>

            <Nav.Link href="#about" 
            className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'}
            onClick={() => onUpdateActiveLink('about')}>
                About
            </Nav.Link>

            <Nav.Link href="#skills" 
            className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'}
            onClick={() => onUpdateActiveLink('skills')}>
                Skills
            </Nav.Link>

            <Nav.Link href="#projects" 
            className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'}
            onClick={() => onUpdateActiveLink('projects')}>
                Projects
            </Nav.Link>

            <Nav.Link href="#connect" 
            className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'}
            onClick={() => onUpdateActiveLink('contact')}>
                Contact
            </Nav.Link>

          </Nav>
          <span className='navbar-text'>
            <div className='social-icon'>
                <a href='https://www.linkedin.com/in/beibit-temirzhanov-367664231/'><img src={navIcon1} alt="LinkedIn" /></a>
                <a href='https://github.com/tmrzhnv' ><img src={navIcon4} alt="" style={{ width: "60%" }}/></a>
            </div>
            <button className='vvd' onClick={downloadFile}>
                <span>Open CV</span>
            </button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )
}